import { useEffect, useState } from "react";
import { getPreferences, updatePreferences } from "./mainPanel.service";

interface Preference {
  Brand: string;
  "Preference Date": string;
  Preferences: {
    Email: {
      Horseguards: string;
      Tower: string;
      Clermont: string;
      "GLH Group": string;
      Thistle: string;
      Cumberland: string;
      Sound: string;
      Vicinity: string;
      Soak: string;
    };
  };
  "Valid At": Date;
  email: string;
  token: string;
}

export const useMainPanel = () => {
  const [pageIsLoaded, setPageIsLoaded] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const [subToken, setSubToken] = useState<string>("");

  const [emailAddress, setEmailAddress] = useState<string>("");

  const [businessIsChecked, setBusinessIsChecked] = useState<boolean>(false);
  const [leisureIsChecked, setLeisureIsChecked] = useState<boolean>(false);

  const [horseguardsSubIsChecked, setHorseguardsSubIsChecked] =
    useState<boolean>(false);
  const [towerSubIsChecked, setTowerSubIsChecked] = useState<boolean>(false);
  const [theClermontSubIsChecked, setTheClermontSubIsChecked] =
    useState<boolean>(false);
  const [thistleHotelsSubIsChecked, setThistleHotelsSubIsChecked] =
    useState<boolean>(false);
  const [allHotelsSubIsChecked, setAllHotelsSubIsChecked] =
    useState<boolean>(false);
  const [theCumberlandSubIsChecked, setTheCumberlandSubIsChecked] =
    useState<boolean>(false);

  const [soundSubIsChecked, setSoundSubIsChecked] = useState<boolean>(false);
  const [vicinitySubIsChecked, setVicinitySubIsChecked] =
    useState<boolean>(false);
  const [soakSubIsChecked, setSoakSubIsChecked] = useState<boolean>(false);

  const [acceptPrivacyPolicyIsChecked, setAcceptPrivacyPolicyIsChecked] =
    useState<boolean>(false);
  const [noLongerReceiveIsChecked, setNoLongerReceiveIsChecked] =
    useState<boolean>(false);

  const [submittedPreferences, setSubmittedPreferences] = useState(false);

  useEffect(() => {
    setPageIsLoaded(false);

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token") ?? "";

    if (!token || token?.length === 0) {
      setSubToken("");
      populateTokenPreference("");

      setInvalidToken(true);
      setPageIsLoaded(true);

      return;
    }

    setSubToken(token);
    populateTokenPreference(token);
    //setDefaultCountry();
  }, [window.location.search]);

  const populateTokenPreference = async (token: string) => {
    const preferences = await getPreferences(token);

    console.log(preferences);

    if (!preferences || !preferences.length) {
      setInvalidToken(true);
      setPageIsLoaded(true);
      return;
    }

    const preference = preferences[0];

    if (!Object.keys(preference).includes("Preferences")) {
      setInvalidToken(true);
      return;
    }

    if (!Object.keys(preference.Preferences).includes("Email")) {
      setInvalidToken(true);
      return;
    }

    const b2cInterefestsPrefs = preference.Preferences.B2C_Interests;
    const emailPrefs = preference.Preferences.Email;

    const isBusiness: boolean = b2cInterefestsPrefs?.Business === "Y";
    const isLeisure: boolean = b2cInterefestsPrefs?.Leisure === "Y";

    const isSubToClermont: boolean = emailPrefs?.Clermont === "Y";
    const isSubToHorseguards: boolean = emailPrefs?.Horseguards === "Y";
    const isSubToTower: boolean = emailPrefs?.Tower === "Y";
    const isSubToThistle: boolean = emailPrefs?.Thistle === "Y";
    const isSubToCumberland: boolean = emailPrefs?.Cumberland === "Y";
    const isSubToSound: boolean = emailPrefs?.Sound === "Y";
    const isSubToVicinity: boolean = emailPrefs?.Vicinity === "Y";
    const isSubToSoak: boolean = emailPrefs?.Soak === "Y";

    setBusinessIsChecked(isBusiness);
    setLeisureIsChecked(isLeisure);

    setTheClermontSubIsChecked(isSubToClermont);
    setHorseguardsSubIsChecked(isSubToHorseguards);
    setTowerSubIsChecked(isSubToTower);
    setThistleHotelsSubIsChecked(isSubToThistle);

    setAllHotelsSubIsChecked(
      isSubToClermont &&
        isSubToHorseguards &&
        isSubToTower &&
        isSubToThistle &&
        isSubToCumberland &&
        isSubToThistle
    );

    setTheCumberlandSubIsChecked(isSubToCumberland);

    setSoundSubIsChecked(isSubToSound);
    setVicinitySubIsChecked(isSubToVicinity);
    setSoakSubIsChecked(isSubToSoak);

    setEmailAddress(preference.email);

    setPageIsLoaded(true);
  };

  const subClick = (subType: string) => {
    let clermontSubChecked: boolean = theClermontSubIsChecked;
    let allSubChecked: boolean = allHotelsSubIsChecked;
    let thistleSubChecked: boolean = thistleHotelsSubIsChecked;
    let cumberlandSubChecked: boolean = theCumberlandSubIsChecked;
    let soundSubChecked: boolean = soundSubIsChecked;
    let vicinitySubChecked: boolean = vicinitySubIsChecked;
    let soakSubChecked: boolean = soakSubIsChecked;
    let horseguardsSubChecked: boolean = horseguardsSubIsChecked;
    let towerSubChecked: boolean = towerSubIsChecked;

    switch (subType.toLocaleLowerCase()) {
      case "clermont":
        clermontSubChecked = !clermontSubChecked;

        setTheClermontSubIsChecked(clermontSubChecked);
        checkAllHotelsClicked();

        break;

      case "allhotels":
        allSubChecked = !allSubChecked;

        setTheClermontSubIsChecked(allSubChecked);
        setTheCumberlandSubIsChecked(allSubChecked);
        setAllHotelsSubIsChecked(allSubChecked);
        setThistleHotelsSubIsChecked(allSubChecked);
        setTowerSubIsChecked(allSubChecked);
        setHorseguardsSubIsChecked(allSubChecked);

        break;

      case "thistle":
        thistleSubChecked = !thistleSubChecked;

        setThistleHotelsSubIsChecked(thistleSubChecked);
        checkAllHotelsClicked();

        break;

      case "cumberland":
        cumberlandSubChecked = !cumberlandSubChecked;

        setTheCumberlandSubIsChecked(cumberlandSubChecked);
        checkAllHotelsClicked();

        break;

      case "sound":
        soundSubChecked = !soundSubChecked;
        setSoundSubIsChecked(soundSubChecked);
        checkAllHotelsClicked();

        break;

      case "vicinity":
        vicinitySubChecked = !vicinitySubChecked;
        setVicinitySubIsChecked(vicinitySubChecked);
        checkAllHotelsClicked();

        break;

      case "soak":
        soakSubChecked = !soakSubChecked;
        setSoakSubIsChecked(soakSubChecked);
        checkAllHotelsClicked();

        break;

      case "horseguards":
        horseguardsSubChecked = !horseguardsSubChecked;
        setHorseguardsSubIsChecked(horseguardsSubChecked);
        checkAllHotelsClicked();

        break;

      case "tower":
        towerSubChecked = !towerSubChecked;
        setTowerSubIsChecked(towerSubChecked);
        checkAllHotelsClicked();

        break;
    }

    function checkAllHotelsClicked() {
      setAllHotelsSubIsChecked(
        clermontSubChecked &&
          thistleSubChecked &&
          cumberlandSubChecked &&
          towerSubChecked &&
          horseguardsSubChecked
      );
    }
  };

  const submitUpdatePreferences = async () => {
    let preferences = {
      //email: {
      Clermont: theClermontSubIsChecked ? "Y" : "N",
      Thistle: thistleHotelsSubIsChecked ? "Y" : "N",
      Cumberland: theCumberlandSubIsChecked ? "Y" : "N",
      Sound: soundSubIsChecked ? "Y" : "N",
      Vicinity: vicinitySubIsChecked ? "Y" : "N",
      Soak: soakSubIsChecked ? "Y" : "N",
      Tower: towerSubIsChecked ? "Y" : "N",
      Horseguards: horseguardsSubIsChecked ? "Y" : "N",
      //}
    };

    let interests = {
      business: businessIsChecked ? "Y" : "N",
      leisure: leisureIsChecked ? "Y" : "N",
    };

    let newPreferences = {
      unsub_token: subToken,
      date_stamp: new Date().toLocaleString("sv"),
      preferences: preferences,
      interests: interests,
    };

    await updatePreferences(newPreferences);

    setSubmittedPreferences(true);
  };

  const setUnsubCheckboxStates = () => {
    setBusinessIsChecked(false);
    setLeisureIsChecked(false);

    setTheClermontSubIsChecked(false);
    setAllHotelsSubIsChecked(false);
    setThistleHotelsSubIsChecked(false);
    setTheCumberlandSubIsChecked(false);
    setHorseguardsSubIsChecked(false);
    setTowerSubIsChecked(false);

    setSoundSubIsChecked(false);
    setVicinitySubIsChecked(false);
    setSoakSubIsChecked(false);

    setAcceptPrivacyPolicyIsChecked(false);

    setNoLongerReceiveIsChecked(!noLongerReceiveIsChecked);
  };

  const unsubscribeClick = async () => {
    let preferences = {
      Clermont: "N",
      Guoman: "N",
      Thistle: "N",
      Cumberland: "N",
      Sound: "N",
      Vicinity: "N",
      Soak: "N",
      Tower: "N",
      Horseguards: "N",
    };

    let interests = {
      business: businessIsChecked ? "Y" : "N",
      leisure: leisureIsChecked ? "Y" : "N",
    };

    let newPreferences = {
      unsub_token: subToken,
      date_stamp: new Date().toLocaleString("sv"),
      preferences: preferences,
      interests: interests,
    };

    const options = {
      headers: {
        authstring: "5df6c913-d041-41d3-9635-ece880dd3ba2",
      },
    };

    await updatePreferences(newPreferences);

    setSubmittedPreferences(true);
  };

  const openPrivacyPolicy = () => {
    window.open(
      "https://www.clermonthotel.group/legals#privacypolicy",
      "Privacy Policy",
      "height=720,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no"
    );
  };

  return {
    pageIsLoaded,
    invalidToken,
    emailAddress,

    businessIsChecked,
    setBusinessIsChecked,
    leisureIsChecked,
    setLeisureIsChecked,

    noLongerReceiveIsChecked,
    subClick,

    towerSubIsChecked,
    horseguardsSubIsChecked,
    theClermontSubIsChecked,
    allHotelsSubIsChecked,
    thistleHotelsSubIsChecked,
    theCumberlandSubIsChecked,

    soundSubIsChecked,
    vicinitySubIsChecked,
    soakSubIsChecked,

    setAcceptPrivacyPolicyIsChecked,
    acceptPrivacyPolicyIsChecked,
    openPrivacyPolicy,
    submitUpdatePreferences,
    submittedPreferences,
    setUnsubCheckboxStates,
    unsubscribeClick,
  };
};
