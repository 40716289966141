import React, { ChangeEvent, ReactNode } from "react";
import style from "./checkboxLabelPair.module.scss";

export interface ICheckBoxLabelPair {
  subClick: any;
  prefName: string;
  subIsChecked: boolean;
  disabled: boolean;
}

const CheckboxLabelPair = ({
  subClick,
  prefName,
  subIsChecked,
  disabled,
}: ICheckBoxLabelPair) => {
  const key = `${prefName}-checkbox`;

  return (
    <>
      <div className={style["checkbox-label-pair"]}>
        <input
          key={key}
          id={key}
          type="checkbox"
          onChange={subClick}
          checked={subIsChecked}
          disabled={disabled}
        ></input>
        <label className={style["checkbox-label"]} htmlFor={key}>
          {prefName}
        </label>
      </div>
    </>
  );
};

export default CheckboxLabelPair;
