import React from "react";
import logo from "./logo.svg";
import style from "./App.module.scss";
import MainPanel from "./components/MainPanel";

function App() {
    return (
        <div className={style.app}>
            <MainPanel />
        </div>
    );
}

export default App;
