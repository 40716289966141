// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxLabelPair_checkbox-label-pair__5VUKj {
  width: 50%;
}
.checkboxLabelPair_checkbox-label-pair__5VUKj .checkboxLabelPair_checkbox-label__GVdOO {
  padding-left: 0.6rem;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/checkbox-label-pair/checkboxLabelPair.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,oBAAA;EACA,sBAAA;AACJ","sourcesContent":[".checkbox-label-pair {\n  width: 50%;\n\n  .checkbox-label {\n    padding-left: 0.6rem;\n    vertical-align: middle;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-label-pair": `checkboxLabelPair_checkbox-label-pair__5VUKj`,
	"checkbox-label": `checkboxLabelPair_checkbox-label__GVdOO`
};
export default ___CSS_LOADER_EXPORT___;
