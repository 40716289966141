import style from "./mainPanel.module.scss";
import clermontHGLogo from "../assets/images/clermont-hotel-group-images/clermont-hg.png";
import clermontLogo from "../assets/images/clermont-hotel-group-images/the_clermont_logo.png";
import thistleLogo from "../assets/images/clermont-hotel-group-images/thistle_logo.png";
import theCumberlandLogo from "../assets/images/the-cumberland-image/the_cumberland_logo.png";
import royalHorseGuardsLogo from "../assets/images/clermont-hotel-group-images/royal-horseguards.png";
import { useMainPanel } from "./useMainPanel";
import CheckboxLabelPair from "./checkbox-label-pair/CheckboxLabelPair";

const MainPanel = () => {
  const {
    pageIsLoaded,
    invalidToken,
    emailAddress,

    businessIsChecked,
    setBusinessIsChecked,
    leisureIsChecked,
    setLeisureIsChecked,

    noLongerReceiveIsChecked,
    subClick,

    horseguardsSubIsChecked,
    towerSubIsChecked,
    theClermontSubIsChecked,
    allHotelsSubIsChecked,
    thistleHotelsSubIsChecked,
    theCumberlandSubIsChecked,

    soundSubIsChecked,
    vicinitySubIsChecked,
    soakSubIsChecked,

    setAcceptPrivacyPolicyIsChecked,
    acceptPrivacyPolicyIsChecked,
    openPrivacyPolicy,
    submitUpdatePreferences,
    submittedPreferences,
    setUnsubCheckboxStates,
    unsubscribeClick,
  } = useMainPanel();

  return (
    <div className={style.main}>
      <div className={style["top-banner"]}>&nbsp;</div>
      <div className={style["images-container"]}>
        <div>
          <img
            src={clermontHGLogo}
            className={`${style["logo"]} ${style["clermont-hg-logo"]}`}
            alt="Clermont Hotels Group Logo"
          />
        </div>
        <div className={style["brand-images-container"]}>
          <div>
            <img
              src={royalHorseGuardsLogo}
              className={`${style["logo"]} ${style["royal-horseguards-logo"]}`}
              alt="Royal Horsegards Logo"
            />
          </div>
          <div>
            <img
              src={clermontLogo}
              className={`${style["logo"]} ${style["clermont-logo"]}`}
              alt="Clermont Hotels Logo"
            />
          </div>
          <div>
            <img
              src={thistleLogo}
              className={`${style["logo"]} ${style["thistle-logo"]}`}
              alt="Thistle Logo"
            />
          </div>
          <div>
            <img
              src={theCumberlandLogo}
              className={`${style["logo"]} ${style["cumberland-logo"]}`}
              alt="The Cumberland Logo"
            />
          </div>
        </div>
      </div>
      <div className={style["form-body"]}>
        {!pageIsLoaded ? (
          <div>
            <h1>Loading Preferences...</h1>
          </div>
        ) : invalidToken ? (
          <div>
            <h1>Invalid Token.</h1>
            <h2>Please contact an administrator.</h2>
          </div>
        ) : !submittedPreferences ? (
          <div>
            <div>
              <h1>Let's stay in touch.</h1>
            </div>
            <div>
              <h4>Update your details to continue hearing from us.</h4>
            </div>
            <hr className={style["top-break"]} />
            <div>
              <h2>Email preferences for...</h2>
            </div>
            <div className={style["input-group"]}>
              <div className={style["details-input-container-left"]}>
                <input
                  className={style["details-input"]}
                  type="email"
                  readOnly
                  placeholder="Email address"
                  value={emailAddress}
                />
              </div>
            </div>
            <div>
              <h2>I'm happy to be contacted about...</h2>
            </div>
            <div className={style["input-group"]}>
              <CheckboxLabelPair
                subClick={() => setBusinessIsChecked(!businessIsChecked)}
                prefName="Business"
                subIsChecked={businessIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
              <CheckboxLabelPair
                subClick={() => setLeisureIsChecked(!leisureIsChecked)}
                prefName="Leisure"
                subIsChecked={leisureIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
            </div>
            <hr className={style["middle-break"]} />
            <div>
              <h2>The hotels I'd like to hear from are...</h2>
            </div>
            <div className={style["input-group"]}>
              <CheckboxLabelPair
                subClick={() => subClick("horseguards")}
                prefName="Royal Horseguards"
                subIsChecked={horseguardsSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
              <CheckboxLabelPair
                subClick={() => subClick("tower")}
                prefName="The Tower"
                subIsChecked={towerSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
            </div>
            <div className={style["input-group"]}>
              <CheckboxLabelPair
                subClick={() => subClick("cumberland")}
                prefName="The Cumberland"
                subIsChecked={theCumberlandSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
              <CheckboxLabelPair
                subClick={() => subClick("clermont")}
                prefName="The Clermont"
                subIsChecked={theClermontSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
            </div>
            <div className={style["input-group"]}>
              <CheckboxLabelPair
                subClick={() => subClick("thistle")}
                prefName="Thistle"
                subIsChecked={thistleHotelsSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
              <CheckboxLabelPair
                subClick={() => subClick("allhotels")}
                prefName="All Hotels"
                subIsChecked={allHotelsSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
            </div>
            <hr className={style["middle-break"]} />
            <div>
              <h2>The restaurants I'd like to hear from are...</h2>
            </div>
            <div className={style["input-group"]}>
              <CheckboxLabelPair
                subClick={() => subClick("sound")}
                prefName="Sound @ The Cumberland"
                subIsChecked={soundSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
              <CheckboxLabelPair
                subClick={() => subClick("vicinity")}
                prefName="Vicinity @ The Tower Hotel"
                subIsChecked={vicinitySubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
            </div>
            <div className={style["input-group"]}>
              <CheckboxLabelPair
                subClick={() => subClick("soak")}
                prefName="Soak @ The Clermont Victoria"
                subIsChecked={soakSubIsChecked}
                disabled={noLongerReceiveIsChecked}
              />
            </div>
            <div className={style["input-group"]}></div>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                onChange={() =>
                  setAcceptPrivacyPolicyIsChecked(!acceptPrivacyPolicyIsChecked)
                }
                id="accept-privacy-policy-checkbox"
                type="checkbox"
                checked={acceptPrivacyPolicyIsChecked}
                disabled={noLongerReceiveIsChecked}
              ></input>
              <label
                className={style["checkbox-label"]}
                htmlFor="accept-privacy-policy-checkbox"
              >
                I accept your
              </label>
              &nbsp;
              <span
                className={style["privacy-policy-btn"]}
                onClick={() => openPrivacyPolicy()}
              >
                privacy policy.
              </span>
            </div>
            <div>
              <input
                className={style["button"]}
                disabled={!acceptPrivacyPolicyIsChecked}
                type="submit"
                value="UPDATE"
                onClick={() => submitUpdatePreferences()}
              ></input>
            </div>
            <hr className={style["middle-break"]} />
            <div>
              <input
                onChange={() => setUnsubCheckboxStates()}
                id="promo-comms-checkbox"
                type="checkbox"
                checked={noLongerReceiveIsChecked}
              ></input>
              <label
                className={style["checkbox-label"]}
                htmlFor="promo-comms-checkbox"
              >
                I no longer wish to receive promotional communications. **
              </label>
            </div>
            <div>
              <input
                className={style["button"]}
                disabled={!noLongerReceiveIsChecked}
                type="button"
                value="UNSUBSCRIBE"
                onClick={() => {
                  unsubscribeClick();
                }}
              ></input>
            </div>
            <div>
              <h5>
                **If you make a booking with our hotel you will only recieve
                communications relevant to your booking.
              </h5>
            </div>
          </div>
        ) : (
          <div>
            <h1>Your Preferences Have Been Submitted</h1>
            <h5>Thank you! You can now close this window.</h5>
            <h5>
              <i>
                <b>Please note:</b> It can take some time for your new
                preferences to update our system.
              </i>
            </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainPanel;
