// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: "Hind";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}

h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
}

h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.2px;
}

h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.2px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,mBAAmB;IACnB,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI;iBACa;AACjB","sourcesContent":["body {\r\n    margin: 0;\r\n    font-family: \"Hind\";\r\n    -webkit-font-smoothing: antialiased;\r\n    -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\nh1 {\r\n    font-weight: 500;\r\n    font-size: 20px;\r\n    line-height: 28px;\r\n}\r\n\r\nh2 {\r\n    font-weight: 500;\r\n    font-size: 16px;\r\n    line-height: 28px;\r\n}\r\n\r\nh3 {\r\n    font-weight: 500;\r\n    font-size: 14px;\r\n    line-height: 28px;\r\n}\r\n\r\nh4 {\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    line-height: 21px;\r\n    letter-spacing: 0.2px;\r\n}\r\n\r\nh5 {\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    line-height: 15px;\r\n    letter-spacing: 0.2px;\r\n}\r\n\r\ncode {\r\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n        monospace;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
