import React from "react";
import axios from "axios";

interface Preference {
  Brand: string;
  "Preference Date": string;
  Preferences: {
    Email: {
      Clermont: string;
      "GLH Group": string;
      Horseguards: string;
      Tower: string;
      Guoman: string;
      Thistle: string;
      Cumberland: string;
      Sound: string;
      Vicinity: string;
      Soak: string;
    };
    B2C_Interests: {
      Business: string;
      Leisure: string;
    };
  };
  "Valid At": Date;
  email: string;
  token: string;
}

export const getPreferences = async (token: string) => {
  //   if (process.env.NODE_ENV === "development") {
  //     const devPref: Preference[] = [
  //       {
  //         Brand: "GLH",
  //         "Preference Date": "2024-07-09",
  //         Preferences: {
  //           Email: {
  //             Clermont: "Y",
  //             "GLH Group": "Y",
  //             Horseguards: "Y",
  //             TheTower: "Y",
  //             Guoman: "Y",
  //             Thistle: "Y",
  //             Cumberland: "Y",
  //             Sound: "Y",
  //             Vicinity: "Y",
  //             Soak: "Y",
  //           },
  //           B2C_Interests: {
  //             Business: "Y",
  //             Leisure: "N",
  //           },
  //         },
  //         "Valid At": new Date("2024-07-09"),
  //         email: "test.user@xcm-uk.com",
  //         token: "123",
  //       },
  //     ];

  //     return devPref;
  //   }

  try {
    return await axios
      .get<Preference[]>(
        `https://glh-pref-backend.horizon.online/api/GetPreferences/${token}?code=jGKr6FW6ZJssOEo1oiLIi-HhvcMjfIccx5Rl3DCu6JJuAzFugn3s0w==`
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  } catch {}
};

export const updatePreferences = async (body: object) => {
  await axios
    .post(
      `https://glh-pref-backend.horizon.online/api/PostPreferences?code=TiAd2xF9AknKlbbnj44c8xOJp1nF49q81-zZetLKT-ueAzFu4yXaGA==`,
      body
    )
    .then((response) => response.data);
};
