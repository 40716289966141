// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__GuJBs {
  display: flex;
  flex-direction: row;
  background-color: #eee;
  width: 100%;
  padding-bottom: 1rem;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,oBAAA;EACA,iBAAA;AACF","sourcesContent":[".app {\r\n  display: flex;\r\n  flex-direction: row;\r\n  background-color: #eee;\r\n  width: 100%;\r\n  padding-bottom: 1rem;\r\n  min-height: 100vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__GuJBs`
};
export default ___CSS_LOADER_EXPORT___;
